.resume-title {
   font-size: 26px;
   font-weight: 700;
   margin-top: 20px;
   margin-bottom: 20px;
   color: var(--headingCol);
}

.resume-item {
   padding: 0 0 20px 20px;
   margin-top: -2px;
   border-left: 2px solid var(--priCol);
   position: relative;
}

.resume-item.pb-0 {
   padding-bottom: 0 !important;
}

.resume-item::before {
   content: "";
   position: absolute;
   width: 16px;
   height: 16px;
   border-radius: 50px;
   left: -9px;
   top: 0;
   background: #fff;
   border: 2px solid var(--priCol);
}

.resume-item h4 {
   line-height: 18px;
   font-size: 18px;
   font-weight: 600;
   text-transform: uppercase;
   color: var(--priCol);
   margin-bottom: 10px;
}

.resume-item h5 {
   font-size: 16px;
   padding: 5px 0;
   display: inline-block;
   font-weight: 600;
   margin-bottom: 10px;
}

.resume-item p {
   margin-bottom: 1rem;
}

.resume-item ul {
   padding-left: 20px;
   margin-bottom: 1rem;
   list-style-type: disc;
}

.resume-item ul li {
   font-size: 16px;
   padding-bottom: 10px;
}
